import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import moment from 'moment';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import 'moment-timezone';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AproveBack from '../modal/AproveBack';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
// add anchors
import { v4 as uuidv4 } from 'uuid';
import NotisReject from '../modal/NotisReject';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '10px',
  backgroundColor: 'var(--background-color)',
  borderBottom: 'var(--border-input)'
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '7px',
  backgroundColor: 'var(--bg-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 14
}));

interface Row {
  userId: any;
  dateId: number;
  date: string;
  startTime: string;
  endTime: string;
  categoryid: any;
  description: string;
  allTime: string;
  tasks: any;
  userName: string;
  category: string;
  categoryTree: string;
  can_approve: boolean;
  superVisor: string;
}

interface ReportsTableProps {
  rows: Row[];
  updateRowsAfterStatusChange: any;
  loading: boolean;
  setLoading: any;
  refreshUserHeder: any;
  filterType: any;
  sortDirection: any;
  setFilterType: any;
}

const ReportsTableAprove: React.FC<ReportsTableProps> = ({
  rows,
  updateRowsAfterStatusChange,
  loading,
  setLoading,
  refreshUserHeder,
  filterType,
  sortDirection,
  setFilterType }) => {
  const [processingReportId, setProcessingReportId] = useState<number | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [reportRows, setReportRows] = useState<Row[]>(rows);
  const [showAprove, setShowAprove] = useState(false);
  const [showReject, setReject] = useState(false);
  const [messege, setMessege] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [rejectRow, setRejectRow] = useState<Row | null>(null);
  const [tableVisibility, setTableVisibility] = useState<{ [userId: string]: boolean }>({});
  // add anchors
  const [userAnchors, setUserAnchors] = useState<{ [userId: string]: string }>({});
  const [categoryAnchors, setCategoryAnchors] = useState<{ [categoryId: string]: string }>({});

  useEffect(() => {
    if (filterType === 'user') {
      const initialVisibility: { [userId: string]: boolean } = {};
      // add anchors
      const anchors: { [userId: string]: string } = {};
      rows.forEach(row => {
        initialVisibility[row.userId] = false;
        // add anchors
        anchors[row.userId] = `anchor-${uuidv4()}`;
      });
      setTableVisibility(initialVisibility);
      // add anchors
      setUserAnchors(anchors);
      setReportRows(rows);
    } else {
      const initialVisibility: { [categoryId: string]: boolean } = {};
      const anchors: { [categoryId: string]: string } = {};
      rows.forEach(row => {
        initialVisibility[row.categoryid] = false;
        anchors[row.categoryid] = `anchor-${uuidv4()}`;
      });
      setTableVisibility(initialVisibility);
      setCategoryAnchors(anchors);
      setReportRows(rows);
    }
  }, [rows, setFilterType, filterType]);

  const handleOpenAproveModal = (reportId: any, week: any) => {
    setSelectedReportId(reportId);
    setSelectedWeek(week);
    setShowAprove(true);
  };

  const handleOpenRejectModal = (reportId: any, userId: any, row: any) => {
    setRejectRow(row);
    setSelectedReportId(reportId);
    setSelectedUserId(userId);
    setMessege('');
    setReject(true);
  };

  const sortedReportRows = useMemo(() => {
    return [...reportRows].sort((a, b) => a.userName.localeCompare(b.userName));
  }, [reportRows]);

  const users = useMemo(() => {
    const groupedUsers: { [userId: string]: { [week: string]: Row[] } } = {};
    sortedReportRows.forEach(row => {
      const week = moment(row.date).isoWeek();
      const year = moment(row.date).year();
      const weekYear = `${year}-W${week}`;
      if (!groupedUsers[row.userId]) {
        groupedUsers[row.userId] = {};
      }
      if (!groupedUsers[row.userId][weekYear]) {
        groupedUsers[row.userId][weekYear] = [];
      }
      groupedUsers[row.userId][weekYear].push(row);
    });
    return groupedUsers;
  }, [reportRows]);

  const categories = useMemo(() => {
    const groupedCategories: { [categoryId: string]: { [week: string]: Row[] } } = {};
    sortedReportRows.forEach(row => {
      const week = moment(row.date).isoWeek();
      const year = moment(row.date).year();
      const weekYear = `${year}-W${week}`;
      if (!groupedCategories[row.categoryid]) {
        groupedCategories[row.categoryid] = {};
      }
      if (!groupedCategories[row.categoryid][weekYear]) {
        groupedCategories[row.categoryid][weekYear] = [];
      }
      groupedCategories[row.categoryid][weekYear].push(row);
    });
    return groupedCategories;
  }, [reportRows]);

  const changeReportStatus = useCallback(async (dateId: number) => {
    setProcessingReportId(dateId);
    setLoading(true);
    try {
      await axios.put('/time-reports/updateStatus', { reportsId: [dateId], status: 'fulfilled' });
      enqueueSnackbar(`Report has been approved successfully`, { variant: 'success' });
      setReportRows((prevRows) => prevRows.filter(row => row.dateId !== dateId));
    } catch (error) {
      console.error(`Error changing status of report with ID ${dateId}:`, error);
      enqueueSnackbar(`Error changing status of report with ID ${dateId}`, { variant: 'error' });
    } finally {
      //go to anchors
      const userId = rows.find(row => row.dateId === dateId)?.userId;
      if (userId) {
        const anchorId = userAnchors[userId];
        const element = document.getElementById(anchorId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
      setProcessingReportId(null);
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  const rejectReport = useCallback(async (dateId: number) => {
    if (!messege.trim()) {
      enqueueSnackbar(`Messege field is emty`, { variant: 'error' });
      return;
    }
    try {
      setProcessingReportId(dateId);
      setLoading(true);
      await axios.post(`notice/add`, {
        message: messege,
        source: `Reject report: date: ${moment(rejectRow?.date).format('DD.MM.YYYY')}, time: ${moment.utc(rejectRow?.startTime).format('HH:mm')}-${moment.utc(rejectRow?.endTime).format('HH:mm')} category: ${rejectRow?.categoryTree}, hours: ${rejectRow?.allTime} `,
        sourceId: dateId,
        severity: 'warning',
        userId: selectedUserId
      });
      await axios.put('/time-reports/updateStatus', { reportsId: [dateId], status: 'rejected' });
      setReportRows((prevRows) => prevRows.filter(row => row.dateId !== dateId));
      enqueueSnackbar(`Report has been rejected`, { variant: 'success' });
    } catch (error) {
      console.error(`Error changing status of report with ID ${dateId}:`, error);
      enqueueSnackbar(`Error changing status of report with ID ${dateId}`, { variant: 'error' });
    } finally {
      setProcessingReportId(null);
      setLoading(false);
      setReject(false);
      refreshUserHeder();
    }
  }, [enqueueSnackbar, reportRows, messege]);

  const approveAllReportsForWeek = useCallback(
    async (identifier: any, week: string, filterType: 'user' | 'category') => {
      setLoading(true);
      const reports = rows.filter((row) => {
        const matchIdentifier = filterType === 'user'
          ? Number(row.userId) === Number(identifier)
          : Number(row.categoryid) === Number(identifier);
        return matchIdentifier && `${moment(row.date).year()}-W${moment(row.date).isoWeek()}` === week;
      });
      try {
        const promises = reports.map(async (report) => {
          if (report.can_approve) {
            await axios.put('/time-reports/updateStatus', { reportsId: [report.dateId], status: 'fulfilled' });
            return report.dateId;
          }
          return null;
        });
        const approvedReportIds = (await Promise.all(promises)).filter((id) => id !== null);
        const identifierName = filterType === 'user' ? reports[0]?.userName : `category ${identifier}`;
        enqueueSnackbar(`Approved ${approvedReportIds.length} out of ${reports.length} reports for ${identifierName} for week ${week}`, { variant: 'success' });
        setReportRows((prevRows) => prevRows.filter(row => !approvedReportIds.includes(row.dateId)));
      } catch (error) {
        console.error('Error approving reports for week:', error);
        enqueueSnackbar(`Error approving some reports for ${filterType === 'user' ? reports[0]?.userName : `category ${identifier}`} for week ${week}`, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, rows]
  );

  const BackReportsForWeek = useCallback(
    async (identifier: number, filterType: 'user' | 'category', week: string) => {
      setLoading(true);
      const filteredReports = rows.filter((row) => {
        return filterType === 'user'
          ? Number(row.userId) === Number(identifier) && `${moment(row.date).year()}-W${moment(row.date).isoWeek()}` === week
          : Number(row.categoryid) === Number(identifier) && `${moment(row.date).year()}-W${moment(row.date).isoWeek()}` === week;
      });
      const canBackAll = filteredReports.every(report => report.can_approve);
      if (!canBackAll) {
        enqueueSnackbar(`Cannot back all reports for ${filterType === 'user' ? 'user' : 'category'} ${identifier} for week ${week}. Some reports cannot be backed.`, { variant: 'error' });
        setLoading(false);
        return;
      }
      try {
        const reportIdsToChange = filteredReports.map(report => report.dateId);
        const response = await axios.put('/time-reports/updateStatus', { reportsId: reportIdsToChange, status: 'new' });
        if (response.status === 200) {
          setReportRows((prevRows) => prevRows.filter((row) => !reportIdsToChange.includes(row.dateId)));
          enqueueSnackbar(`All reports for ${filterType === 'user' ? 'user' : 'category'} ${identifier} for week ${week} have been back to new`, { variant: 'success' });
        } else {
          enqueueSnackbar(`Can't back week`, { variant: 'error' });
        }
      } catch (error) {
        console.error('Error back reports for week:', error);
        enqueueSnackbar(`Error back reports for ${filterType === 'user' ? 'user' : 'category'} ${identifier} for week ${week}`, { variant: 'error' });
      } finally {
        setLoading(false);
        setShowAprove(false);
      }
    },
    [enqueueSnackbar, rows]
  );

  const calculateTotalTime = useCallback((userRows: Row[]) => {
    return userRows
      .reduce((total, row) => {
        const [hours, minutes] = row.allTime.split(':').map(parseFloat);
        return total + hours + minutes / 60;
      }, 0)
      .toFixed(2);
  }, []);

  const calculateWeeklyTotalTime = useCallback((userRows: Row[]) => {
    const weeklyTotalTimes: { [week: string]: number } = {};
    userRows.forEach(row => {
      const week = moment(row.date).isoWeek();
      const year = moment(row.date).year();
      const weekYear = `${year}-W${week}`;
      if (!weeklyTotalTimes[weekYear]) {
        weeklyTotalTimes[weekYear] = 0;
      }
      const [hours, minutes] = row.allTime.split(':').map(parseFloat);
      weeklyTotalTimes[weekYear] += hours + minutes / 60;
    });
    return weeklyTotalTimes;
  }, []);

  const formatTotalTime = useCallback((totalTime: any) => {
    const hours = Math.floor(totalTime);
    const minutes = Math.round((totalTime - hours) * 60);
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  }, []);

  const renderUserReports = useCallback(() => {
    const sortedUsers = Object.entries(users).sort(([userIdA, weeksA], [userIdB, weeksB]) => {
      const userNameA = Object.values(weeksA)[0][0].userName;
      const userNameB = Object.values(weeksB)[0][0].userName;
      return userNameA.localeCompare(userNameB);
    });

    return sortedUsers.map(([userId, weeks]) => {
      const allUserRows = Object.values(weeks).flat();
      const sortedWeeks = Object.entries(weeks).sort(([weekA], [weekB]) => {
        const dateA = moment(Object.values(weeks[weekA])[0].date).startOf('isoWeek');
        const dateB = moment(Object.values(weeks[weekB])[0].date).startOf('isoWeek');
        return sortDirection === 'asc' ? dateA.diff(dateB) : dateB.diff(dateA);
      });
      return (
        <div
          key={userId}
          id={userAnchors[userId]}
          className={`text-center rounded-[5px] mb-[10px] px-[10px] bg-[var(--bg-aprove)] user-box-view-all ${tableVisibility[userId] ? 'pb-[10px]' : 'hover:bg-[var(--bg-header-fix-block)]'}`}
        >
          <div
            className='flex justify-between items-center p-[10px] cursor-pointer'
            onClick={() =>
              setTableVisibility((prevState) => {
                const newState = Object.keys(prevState).reduce((acc: any, key) => {
                  acc[key] = key === userId ? !prevState[key] : false;
                  return acc;
                }, {});

                return newState;
              })
            }
          >
            <h3>{Object.values(weeks)[0][0].userName}</h3>
            <div className='flex items-center gap-3 justify-normal'>
              <div>
                {t('Total')}: <span className='font-semibold'>{formatTotalTime(calculateTotalTime(allUserRows))}</span>
              </div>
              <FontAwesomeIcon
                icon={faArrowUp}
                className={`cursor-pointer opacity-70 px-[8px] py-[5px] rounded-full transition-[1s] hover:bg-gray-500 hover:text-white ${tableVisibility[userId] ? 'rotate-0' : 'rotate-180'}`}
              />
            </div>
          </div>
          {tableVisibility[userId] && (
            <div className='user-box-view-all rounded-[5px]'>
              {sortedWeeks.map(([week, userRows]) => {
                const sortedUserRows = userRows.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
                return (
                  <div key={week}>
                    <div className='flex items-center font-bold gap-[10px] justify-start'>
                      <h4 className='text-right px-[5px] bg-[var(--background-color)] font-bold'>
                        {`${moment(sortedUserRows[0].date).startOf('isoWeek').format('DD.MM.YYYY')} - ${moment(sortedUserRows[0].date).endOf('isoWeek').format('DD.MM.YYYY')}`}
                      </h4>
                    </div>
                    <TableContainer
                      component={Paper}
                      style={{
                        boxShadow: 'none',
                        backgroundColor: 'inherit',
                        borderBottom: 'var(--border-input)',
                      }}
                    >
                      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>{t('Name')}</StyledTableCell>
                            <StyledTableCell>{t('Date')}</StyledTableCell>
                            <StyledTableCell>{t('StartTime')}</StyledTableCell>
                            <StyledTableCell>{t('EndTime')}</StyledTableCell>
                            <StyledTableCell>{t('Category')}</StyledTableCell>
                            <StyledTableCell>{t('Task')}</StyledTableCell>
                            <StyledTableCell>{t('Supervisor')}</StyledTableCell>
                            <StyledTableCell>{t('Description')}</StyledTableCell>
                            <StyledTableCell align='right'>{t('Hours')}</StyledTableCell>
                            <StyledTableCell align='right' width='6%'>{t('Approve')}</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sortedUserRows.map((row, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <StyledTableRow>{row.userName}</StyledTableRow>
                              <StyledTableRow>{moment(row.date).format('DD.MM.YYYY')}</StyledTableRow>
                              <StyledTableRow>{moment.utc(row.startTime).format('HH:mm')}</StyledTableRow>
                              <StyledTableRow>{moment.utc(row.endTime).format('HH:mm')}</StyledTableRow>
                              <StyledTableRow>{row.categoryTree}</StyledTableRow>
                              <StyledTableRow>
                                {row.tasks ? row.tasks.map((task: any) => task.name).join(', ') : null}
                              </StyledTableRow>
                              <StyledTableRow>{row.superVisor}</StyledTableRow>
                              <StyledTableRow>{row.description}</StyledTableRow>
                              <StyledTableRow align='right'>{row.allTime}</StyledTableRow>
                              <StyledTableRow align='right'>
                                <div className='flex gap-2 item-center'>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      padding: '3px 10px',
                                      fontSize: 12,
                                      color: 'white !important'
                                    }}
                                    disabled={processingReportId === row.dateId || !row.can_approve}
                                    onClick={() => changeReportStatus(Number(row.dateId))}>
                                    {t('Approve')}
                                  </Button>
                                  <Button
                                    color="error"
                                    variant="contained"
                                    sx={{
                                      padding: '3px 10px',
                                      fontSize: 12,
                                      color: 'white !important'
                                    }}
                                    disabled={processingReportId === row.dateId || !row.can_approve}
                                    onClick={() => handleOpenRejectModal(Number(row.dateId), Number(row.userId), row)}>
                                    {t('Return')}
                                  </Button>
                                </div>
                              </StyledTableRow>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div className='flex items-center gap-[10px] justify-between p-[15px] pb-[20px] aprove-border-table w-full'>
                      <div>
                        <Button
                          variant="outlined"
                          sx={{
                            fontSize: 13,
                            textTransform: 'none'
                          }}
                          onClick={() => handleOpenAproveModal(userId, week)}
                          disabled={loading}>
                          {t('RemoveAllForWeek')}
                        </Button>
                      </div>
                      <div className='flex items-center gap-[10px]'>
                        <div>
                          {t('TotalWeek')}: {formatTotalTime(calculateWeeklyTotalTime(sortedUserRows)[week])}
                        </div>
                        <Button
                          variant="contained"
                          endIcon={<PlaylistAddCheckIcon
                            sx={{ color: 'white !important' }} />}
                          sx={{
                            padding: '5px 10px',
                            fontSize: 12,
                            color: 'white !important'
                          }}
                          disabled={loading}
                          onClick={() => approveAllReportsForWeek(userId, week, 'user')}>
                          {t('ApproveAllForWeek')}
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    });
  }, [
    approveAllReportsForWeek,
    calculateTotalTime,
    changeReportStatus,
    formatTotalTime,
    rejectReport,
    tableVisibility,
    users,
    processingReportId,
    sortDirection
  ]);

  const renderCategoryReports = useCallback(() => {
    const sortedCategories = Object.entries(categories).sort(([categoryIdA, weeksA], [categoryIdB, weeksB]) => {
      const categoryNameA = Object.values(weeksA)[0][0].categoryTree;
      const categoryNameB = Object.values(weeksB)[0][0].categoryTree;
      return categoryNameA.localeCompare(categoryNameB);
    });

    return sortedCategories.map(([categoryId, weeks]) => {
      const allCategoryRows = Object.values(weeks).flat();
      const sortedWeeks = Object.entries(weeks).sort(([weekA], [weekB]) => {
        const dateA = moment(Object.values(weeks[weekA])[0].date).startOf('isoWeek');
        const dateB = moment(Object.values(weeks[weekB])[0].date).startOf('isoWeek');
        return sortDirection === 'asc' ? dateA.diff(dateB) : dateB.diff(dateA);
      });

      return (
        <div
          key={categoryId}
          id={categoryAnchors[categoryId]}
          className={`text-center rounded-[5px] mb-[10px] px-[10px] bg-[var(--bg-aprove)] category-box-view-all ${tableVisibility[categoryId] ? 'pb-[10px]' : 'hover:bg-[var(--bg-header-fix-block)]'}`}
        >
          <div
            className='flex justify-between items-center p-[10px] cursor-pointer'
            onClick={() =>
              setTableVisibility((prevState) => {
                const newState = Object.keys(prevState).reduce((acc: any, key) => {
                  acc[key] = key === categoryId ? !prevState[key] : false;
                  return acc;
                }, {});
                return newState;
              })
            }
          >
            <h3>{Object.values(weeks)[0][0].categoryTree}</h3>
            <div className='flex items-center gap-3 justify-normal'>
              <div>
                {t('Total')}: <span className='font-semibold'>{formatTotalTime(calculateTotalTime(allCategoryRows))}</span>
              </div>
              <FontAwesomeIcon
                icon={faArrowUp}
                className={`cursor-pointer opacity-70 px-[8px] py-[5px] rounded-full transition-[1s] hover:bg-gray-500 hover:text-white ${tableVisibility[categoryId] ? 'rotate-0' : 'rotate-180'}`}
              />
            </div>
          </div>
          {tableVisibility[categoryId] && (
            <div className='category-box-view-all rounded-[5px]'>
              {sortedWeeks.map(([week, categoryRows]) => {
                const sortedCategoryRows = categoryRows.sort((rowA, rowB) => moment(rowA.date).diff(moment(rowB.date)));
                return (
                  <div key={week}>
                    <div className='flex items-center font-bold gap-[10px] justify-start'>
                      <h4 className='text-right px-[5px] bg-[var(--background-color)] font-bold'>
                        {`${moment(categoryRows[0].date).startOf('isoWeek').format('DD.MM.YYYY')} - ${moment(categoryRows[0].date).endOf('isoWeek').format('DD.MM.YYYY')}`}
                      </h4>
                    </div>

                    <TableContainer
                      component={Paper}
                      style={{
                        boxShadow: 'none',
                        backgroundColor: 'inherit',
                        borderBottom: 'var(--border-input)',
                      }}
                    >
                      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>{t('Name')}</StyledTableCell>
                            <StyledTableCell>{t('Date')}</StyledTableCell>
                            <StyledTableCell>{t('StartTime')}</StyledTableCell>
                            <StyledTableCell>{t('EndTime')}</StyledTableCell>
                            <StyledTableCell>{t('Category')}</StyledTableCell>
                            <StyledTableCell>{t('Task')}</StyledTableCell>
                            <StyledTableCell>{t('Supervisor')}</StyledTableCell>
                            <StyledTableCell>{t('Description')}</StyledTableCell>
                            <StyledTableCell align='right'>{t('Hours')}</StyledTableCell>
                            <StyledTableCell align='right' width='6%'>{t('Approve')}</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sortedCategoryRows.map((row, index) => (
                            <TableRow key={index}>
                              <StyledTableRow>{row.userName}</StyledTableRow>
                              <StyledTableRow>{moment(row.date).format('DD.MM.YYYY')}</StyledTableRow>
                              <StyledTableRow>{moment.utc(row.startTime).format('HH:mm')}</StyledTableRow>
                              <StyledTableRow>{moment.utc(row.endTime).format('HH:mm')}</StyledTableRow>
                              <StyledTableRow>{row.categoryTree}</StyledTableRow>
                              <StyledTableRow>{row.tasks ? row.tasks.map((task: any) => task.name).join(', ') : null}</StyledTableRow>
                              <StyledTableRow>{row.superVisor}</StyledTableRow>
                              <StyledTableRow>{row.description}</StyledTableRow>
                              <StyledTableRow align='right'>{row.allTime}</StyledTableRow>
                              <StyledTableRow align='right'>
                                <div className='flex gap-2'>
                                  <Button
                                    variant='contained'
                                    sx={{
                                      padding: '3px 10px',
                                      fontSize: 12,
                                      color: 'white !important'
                                    }}
                                    disabled={processingReportId === row.dateId || !row.can_approve}
                                    onClick={() => changeReportStatus(row.dateId)}>
                                    {t('Approve')}
                                  </Button>
                                  <Button
                                    color='error'
                                    variant='contained'
                                    sx={{
                                      padding: '3px 10px',
                                      fontSize: 12,
                                      color: 'white !important'
                                    }}
                                    disabled={processingReportId === row.dateId || !row.can_approve}
                                    onClick={() => handleOpenRejectModal(row.dateId, row.userId, row)}>
                                    {t('Return')}
                                  </Button>
                                </div>
                              </StyledTableRow>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div className='flex items-center gap-[10px] justify-between p-[15px]'>
                      <div>
                        <Button variant='outlined' onClick={() => handleOpenAproveModal(categoryId, week)} disabled={loading}>
                          {t('RemoveAllForWeek')}
                        </Button>
                      </div>
                      <div className='flex items-center gap-[10px]'>
                        <div>
                          {t('TotalWeek')}: {formatTotalTime(calculateWeeklyTotalTime(categoryRows)[week])}
                        </div>
                        <Button
                          variant='contained'
                          endIcon={<PlaylistAddCheckIcon
                            sx={{
                              color: 'white !important'
                            }} />}
                          sx={{
                            padding: '5px 10px',
                            fontSize: 12,
                            color: 'white !important'
                          }}
                          disabled={loading}
                          onClick={() => approveAllReportsForWeek(categoryId, week, 'category')}>
                          {t('ApproveAllForWeek')}
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    });
  }, [
    approveAllReportsForWeek,
    calculateTotalTime,
    changeReportStatus,
    formatTotalTime,
    rejectReport,
    tableVisibility,
    users,
    processingReportId,
    sortDirection
  ]);


  return <div>
    {loading ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <CircularProgress />
      </Box>
    ) :
      (
        <div>
          {filterType === 'user' ? renderUserReports() : renderCategoryReports()}
        </div>
      )}
    {showReject &&
      <NotisReject
        showReject={showReject}
        setReject={setReject}
        rejectReport={rejectReport}
        reportId={selectedReportId}
        setMessege={setMessege} />}
    {showAprove &&
      <AproveBack
        showAprove={showAprove}
        setShowAprove={setShowAprove}
        BackReportsForWeek={BackReportsForWeek}
        reportId={selectedReportId}
        filterType={filterType}
        week={selectedWeek} />}
  </div>;
};

export default ReportsTableAprove;
