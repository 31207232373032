import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Nav from "./components/nav/Nav";
import Timesheets from "./components/Timesheets";
import Reports from "./components/Reports";
import { Forgot } from "./components/auth/Forgot";
import { Reset } from "./components/auth/Reset";
import Approve from "./components/Approve";
import Settings from "./components/Settings";
import Header from "./components/nav/Header";
import { useEffect, useState } from "react";
import { setAuth } from './redux/authSlice';
import { RootState } from './redux/store';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { SnackbarProvider } from 'notistack';
import Home from "./components/Home";
import Clients from "./components/Clients";
import Equipment from "./components/Equipment";
import Rent from "./components/Booking";
import Expenses from "./components/Expenses";
import Orders from "./components/Orders";
import Users from "./components/Users";
import UsersReports from "./components/UserReports";
import Toil from "./components/Toil";
import './components/i18n/i18n';
import Template from "./components/Template";

function App() {
  const auth = useSelector((state: RootState) => state.auth.value);
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState('');
  const [userRole, setUserRole] = useState('');
  const [userNotice, setUserNotice] = useState([]);
  const [urlImage, setUrlImage] = useState('');
  const [urlLogo, setUrlLogo] = useState('');
  const [selectedOffset, setSelectedOffset] = useState<string | null>(null);
  const [timeZone, setTimeZone] = useState<string | null>(null);
  const [accessRole, setAccessRole] = useState<Record<string, any>>({});
  const [is24HourFormat, setIs24HourFormat] = useState(() => {
    const storedFormat = localStorage.getItem('is24HourFormat');
    return storedFormat ? JSON.parse(storedFormat) : true;
  });
  const [baseUrl, setBaseUrl] = useState('https://timesheets.alegros.co.uk:8089/');
  const getLanguage = () => {
    let language = localStorage.getItem('language');
    if (!language) {
      language = 'en';
      localStorage.setItem('language', language);
    }
    return language;
  };
  const [lng, setLng] = useState(getLanguage);
  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    const storedTheme = localStorage.getItem('darkTheme');
    return storedTheme ? JSON.parse(storedTheme) : false;
  });

  useEffect(() => {
    const hostname = window.location.hostname;
    if (hostname === 'localhost') {
      setBaseUrl('http://localhost:8000/');
    } else {
      setBaseUrl('https://timesheets.alegros.co.uk:8089/');
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get('user');
        if (data) {
          setTimeZone(data.company.zone_description)
          setUserNotice(data.notice);
          setAccessRole(data.role);
          setUserRole(`${data.role.color}`);
          dispatch(setAuth(true));
          setUserInfo(`${data.first_name} ${data.last_name}`);
          setUrlImage(data.avatarUrl)
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        dispatch(setAuth(false));
      }
    };

    if (auth) {
      fetchData();
    }
  }, [auth, dispatch, userInfo, userRole]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get('user');
        if (data.avatarUrl) {
          setUrlImage(`${baseUrl}${data.avatarUrl}`);
        }
        if (data.company.logoUrl) {
          setUrlLogo(`${baseUrl}${data.company.logoUrl}`);
        }
      } catch (e) { }
    })();
  }, [urlImage, urlLogo, baseUrl]);

  const refreshUserHeder = async () => {
    try {
      const { data } = await axios.get('user');
      if (data) {
        setUserNotice(data.notice);
        setUserInfo(`${data.first_name} ${data.last_name}`);
        if (data.avatarUrl) {
          setUrlImage(`${baseUrl}${data.avatarUrl}`);

        } else {
          setUrlImage('');
        }
        if (data.company.logoUrl) {
          setUrlLogo(`${baseUrl}${data.company.logoUrl}`);
        } else {
          setUrlLogo('');
        }
        const { data: dataRole } = await axios.get(`roles/${data.roleId}`);
        setUserRole(`${dataRole.color}`);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const refreshRoleAccept = async () => {
    try {
      const { data } = await axios.get('user');
      if (data) {
        setAccessRole(data.role);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleTimeFormatChange = () => {
    const newFormat = !is24HourFormat;
    setIs24HourFormat(newFormat);
    localStorage.setItem('is24HourFormat', JSON.stringify(newFormat));
  };

  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={1900} className="opacity-80">
      <BrowserRouter>
        <Nav isDarkTheme={isDarkTheme} access={accessRole} urlLogo={urlLogo} />
        <div className={`w-full relative overflow-hidden container-body ${auth ? 'pl-[var(--view-nav)]' : 'pl-0'}`}>
          <Header
            isDarkTheme={isDarkTheme}
            setIsDarkTheme={setIsDarkTheme}
            userInfo={userInfo}
            userRole={userRole}
            refreshUserHeder={refreshUserHeder}
            lng={lng}
            setLng={setLng}
            userNotice={userNotice}
            urlImage={urlImage}
            baseUrl={baseUrl}
            is24HourFormat={is24HourFormat}
            timeZone={timeZone}
          />
          <div className="z-0 overflow-auto h-[94svh] mr-[-5px] py-[10px] px-[15px] wrapper-all">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/timesheets" element={<Timesheets
                access={accessRole}
                is24HourFormat={is24HourFormat} />} />
              <Route path="/reports" element={<Reports access={accessRole} />} />
              <Route path="/approve" element={<Approve access={accessRole} refreshUserHeder={refreshUserHeder} />} />
              <Route path="/clients" element={<Clients access={accessRole} />} />
              <Route path="/template" element={<Template access={accessRole} is24HourFormat={is24HourFormat} />} />
              <Route path="/equipment" element={<Equipment access={accessRole} />} />
              <Route path="/booking" element={<Rent access={accessRole} />} />
              <Route path="/expenses" element={<Expenses access={accessRole} />} />
              <Route path="/orders" element={<Orders access={accessRole} />} />
              <Route path="/settings" element={<Settings
                refreshUserHeder={refreshUserHeder}
                refreshRoleAccept={refreshRoleAccept}
                baseUrl={baseUrl}
                is24HourFormat={is24HourFormat}
                handleTimeFormatChange={handleTimeFormatChange}
                timeZone={timeZone}
                setTimeZone={setTimeZone}
                setSelectedOffset={setSelectedOffset}
                selectedOffset={selectedOffset} />} />
              <Route path="/users" element={<Users
                refreshUserHeder={refreshUserHeder}
                access={accessRole}
                baseUrl={baseUrl} />} />
              <Route path="/toil" element={<Toil
                access={accessRole}
                baseUrl={baseUrl} />} />
              <Route path="/users-reports" element={<UsersReports />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot" element={<Forgot />} />
              <Route path="/reset/:token" element={<Reset />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </SnackbarProvider>
  );
}

export default App;
