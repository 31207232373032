import React, { useEffect, useState, useCallback } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store'
import ReportsTableAprove from './aproveComponents/ReportsTableAprove';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Breadcrums from './util/Breadcrums';
import { useLocation } from "react-router-dom";
import { t } from 'i18next';

interface ApproveProps {
  access: any,
  refreshUserHeder: any
}

const Approve: React.FC<ApproveProps> = ({ access, refreshUserHeder }) => {
  const [reportPending, setReportsPending] = useState<any[]>([]);
  const [userId, setUserID] = useState();
  const [rows, setRows] = useState<any[]>([]);
  const auth = useSelector((state: RootState) => state.auth.value);
  const dispatch = useDispatch();
  const [locationName, setLocationName] = useState('');
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [sortDirection, setSortDirection] = useState('asc');
  const [filterType, setFilterType] = useState('user');

  const updateRowsStatusChange = () => {
    const updatedRows = reportPending.map(item => {
      const startTime = moment(item.startTime);
      const endTime = moment(item.endTime);
      let duration = moment.duration(endTime.diff(startTime));
      if (duration.asMinutes() < 0) {
        duration = moment.duration(endTime.add(1, 'day').diff(startTime));
      }
      const diffInMinutes = endTime.diff(startTime, 'minutes');
      const roundedMinutes = Math.ceil(diffInMinutes / 5) * 5;
      return {
        userId: item.userId,
        date: item.date,
        startTime: item.startTime,
        endTime: item.endTime,
        categoryid: item.categoryId,
        description: item.description,
        dateId: item.id,
        categoryTree: `${item.categoryTree}`,
        category: `${item.category.name}`,
        userName: `${item.user?.first_name} ${item.user?.last_name}`,
        superVisor: `${item.supervisor ? (`${item.supervisor?.first_name} ${item.supervisor?.last_name}`) : ''}`,
        tasks: item.tasks,
        can_approve: item.can_approve,
        allTime: `${Math.floor(roundedMinutes / 60)}:${(roundedMinutes % 60).toFixed(0).padStart(2, '0')}`
      };
    });
    setRows(updatedRows);
  };

  const handleFilterChange = (event: any) => {
    setFilterType(event.target.value);
  };

  const updateRowsAfterStatusChange = useCallback(async () => {
    setLoading(true);
    try {
      const { data: supervisor } = await axios.get(`time-reports/${userId}`);
      setReportsPending(supervisor);
      updateRowsStatusChange();
    } catch (error) {
      console.error('Error updating rows after status change:', error);
    } finally {
      setLoading(false);
    }
  }, [userId, setReportsPending, updateRowsStatusChange]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await axios.get('user');
        const { data: supervisorPending } = await axios.get(`time-reports/${data.id}`);
        if (data) {
          setUserID(data.id);
          dispatch(setAuth(true));
        }
        setReportsPending(supervisorPending);
        setLocationName(location.pathname);
      } catch (e) {
        dispatch(setAuth(false));
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch, location.pathname]);

  useEffect(() => {
    updateRowsStatusChange();
  }, [reportPending]);

  return (
    <div>
      <Breadcrums locationName={locationName} />
      <div
        className='bg-[var(--bg-form)] w-full box-users gap-[5px] p-[15px] mb-[15px] rounded-[5px]'>
        <div className='flex justify-between items-center mb-[10px]'>
          <div>
            <h3 className='text-[20px]'>{t('Approve')}</h3>
          </div>
          <div className='flex items-center gap-2 justify-end'>
            <p className='text-[16px] font-semibold'>{t('SortBy')}:</p>
            <select
              value={filterType}
              className='admin-sort-user-select bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]'
              onChange={handleFilterChange}>
              <option value="user">user</option>
              <option value="category">category</option>
            </select>
            <select
              id="sortDirection"
              value={sortDirection}
              onChange={(e) => setSortDirection(e.target.value)}
              className="admin-sort-user-select bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]"
            >
              <option value="asc">{t('week')} ⇈</option>
              <option value="desc">{t('week')} ⇊</option>
            </select>
          </div>
        </div>
      </div>
      <div
        className="bg-[var(--bg-form)] w-full box-users gap-[5px] p-[15px] mb-[15px] rounded-[5px] max-h-[96vh] overflow-y-auto">
        {rows.length > 0 || !loading ? (
          auth && access.approve_get > 0 ? (
            <ReportsTableAprove
              rows={rows}
              updateRowsAfterStatusChange={updateRowsAfterStatusChange}
              setLoading={setLoading}
              loading={loading}
              refreshUserHeder={refreshUserHeder}
              filterType={filterType}
              sortDirection={sortDirection}
              setFilterType={setFilterType} />
          ) :
            (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
              <CircularProgress />
            </Box>)
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress />
          </Box>
        )}
      </div>
    </div>
  )
}

export default Approve